/* eslint-disable react/prop-types */
import React, { useContext, useState, useEffect, useRef } from "react";
import {
  Table,
  Input,
  InputNumber,
  Popconfirm,
  Form,
  Modal,
  Typography,
  Button,
  Switch,
  message,
  Select,
} from "antd";
import {
  updateConsignerType,
  createConsignerType,
  getConsignerTypes,
} from "../../redux/actions/consignerTypeActions";

import { AppDispatch } from "../../redux/store";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import getSymbolFromCurrency from "currency-symbol-map";
import { getInventoryFilterOptions } from "../../redux/actions/inventoryActions";
import { InventoryState } from "../../redux/reducers/InventoryReducer";

interface Props {
  tableData: any;
  loading: boolean;
  savedLoading: boolean;
  productTemplates: any;
  store: any;
}

interface Item {
  key: string;
  accountType: string;
  sellingFee: number;
  minFee: number;
  flatFee: number;
  isDefault: boolean;
  category: any;
  categories: any;
  option1Value: string;
  option2Value: string;
  option3Value: string;
  isPaid: boolean;
  tierName: string;
  isLocationTier: boolean;
  location: string;
}

export interface ConsignerTypeItem {
  id: any;
  accountType: string;
  sellingFee: number;
  minFee: number;
  flatFee: number;
  isDefault: boolean;
  categories: any;
  category: string;
  option1Value: string;
  option2Value: string;
  option3Value: string;
  isPaid: boolean;
  tierName: string;
  isLocationTier: boolean;
  location: string;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text";
  record: Item;
  index: number;
  children: React.ReactNode;
  required: boolean;
}

const onUpdate = async (
  dispatch: AppDispatch,
  consignerType: ConsignerTypeItem,
  id: string
) => {
  dispatch(await updateConsignerType(id, consignerType));
};

const onSave = async (
  dispatch: AppDispatch,
  consignerType: ConsignerTypeItem
) => {
  dispatch(await createConsignerType(consignerType));
};

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  required,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const ConsignerTypeTable = (props: Props) => {
  const { tableData, loading, savedLoading, productTemplates, store } = props;
  const dispatch = useAppDispatch();

  const { inventoryFilterOptions }: InventoryState = useAppSelector(
    (state) => state.InventoryReducer
  );

  const formatedData = tableData.map((item: any) => ({
    key: item.id.toString(),
    accountType: item.accountType,
    sellingFee: item.sellingFee,
    minFee: item.minFee,
    flatFee: item.flatFee,
    isDefault: item.isDefault,
    categories: item.categories,
    category: item.category,
    option1Value: item.option1Value,
    option2Value: item.option2Value,
    option3Value: item.option3Value,
    isPaid: item.isPaid,
    tierName: item.tierName,
    isLocationTier: item.isLocationTier,
    location: item.location,
  }));

  let formatedCatData = productTemplates.map((item: any) => ({
    key: item.id.toString(),
    category: item.category,
    sellingFee: item.sellingFee,
    // isDefault: item.isDefault,
  }));

  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [data, setData] = useState(formatedData.length > 0 ? formatedData : []);
  const [categoryData, setCategoryData] = useState(
    formatedCatData.length > 0 ? formatedData : []
  );
  const [editingKey, setEditingKey] = useState("");

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [switchToggle, setSwitchToggle] = useState(false);
  const [isLocationTierEnabled, setIsLocationTierEnabled] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>("");

  useEffect(() => {
    let arr1 = productTemplates;
    let arr2 = selectedRow.categories;
    let newArr: any;

    if (arr1 && arr1.length > 0 && arr2 && arr2.length > 0) {
      newArr = Array.from(new Set(arr1.map((s: any) => s.category))).map(
        (template: any, index: any) => {
          return {
            key: index.toString(),
            category: template,
            sellingFee: arr2.find((fee: any) => fee.category === template)
              ? arr2.find((fee: any) => fee.category === template).sellingFee
              : "",
            minFee: arr2.find((fee: any) => fee.category === template)
              ? arr2.find((fee: any) => fee.category === template).minFee
              : "",
            flatFee: arr2.find((fee: any) => fee.category === template)
              ? arr2.find((fee: any) => fee.category === template).flatFee
              : "",
          };
        }
      );
      setCategoryData(newArr);
    } else {
      newArr = Array.from(new Set(arr1.map((s: any) => s.category))).map(
        (template: any, index: any) => {
          return {
            key: index.toString(),
            category: template,
            sellingFee: "",
          };
        }
      );
      setCategoryData(newArr);
    }
  }, [selectedRow]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    form2.resetFields();
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (formatedData.length > 0) {
      setData(formatedData);
    }
  }, [tableData]);

  useEffect(() => {
    if (formatedCatData.length > 0) {
      setCategoryData(formatedCatData);
    }
  }, [productTemplates]);

  useEffect(() => {
    dispatch(getInventoryFilterOptions());
  }, []);

  const isEditing = (record: Item) => record.key === editingKey;

  const edit = (record: Partial<Item> & { key: React.Key }) => {
    form.setFieldsValue({ accountType: "", sellingFee: "", ...record });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as Item;
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      const savedItem = {
        id: key,
        accountType: row.accountType,
        sellingFee: row.sellingFee,
        minFee: row.minFee,
        flatFee: row.flatFee,
        isDefault: row.isDefault,
        categories: row.categories,
        category: row.category,
        option1Value: row.option1Value,
        option2Value: row.option2Value,
        option3Value: row.option3Value,
        isPaid: row.isPaid,
        tierName: row.tierName,
        isLocationTier: row.isLocationTier,
        location: row.location,
      };
      // if id is blank, create new item
      if (key === "") {
        onSave(dispatch, savedItem);
      }
      //else update existing item
      onUpdate(dispatch, savedItem, key.toString());
      //

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const handleAdd = (values: any) => {
    const savedItem = {
      id: "",
      accountType: values.accountType,
      category: values.category,
      option1Value: values.option1Value,
      option2Value: values.option2Value,
      option3Value: values.option3Value,
      sellingFee: Number(values.sellingFee),
      minFee: Number(values.minFee),
      flatFee: Number(values.flatFee),
      isDefault: values.isDefault,
      categories: values.categories,
      isPaid: values.isPaid,
      tierName: values.tierName,
      isLocationTier: values.isLocationTier,
      location: values.isLocationTier ? values.location : "",
    };

    // Validation for isLocationTier and isPaid
    if (values.isLocationTier) {
      const locationCount = data.filter(
        (i) => i.isLocationTier === true
      ).length;

      if (!values.isPaid) {
        message.error("You need to make this package paid.");
        return; // Prevent further execution if validation fails
      }

      if (locationCount > 0) {
        message.error("You can only add one location membership.");
        return;
      }
    }

    if (values.isPaid) {
      const paidCount = data.filter((i) => i.isPaid === true).length;

      if (paidCount >= 3) {
        message.error(
          "You can't add more than 3 paid memberships. Disable the paid toggle."
        );
        return;
      }
    }

    // Proceed with saving
    onSave(dispatch, savedItem);

    // Dispatch only if saving is done
    if (!savedLoading) {
      setTimeout(() => dispatch(getConsignerTypes()), 500);
    }

    // Reset form and close modal
    form2.resetFields();
    setIsModalVisible(false);
  };

  const handleSwitchChange = async (checked: any, value: any) => {
    await setSwitchToggle(checked);
    const updateItem = {
      id: value.key,
      accountType: value.accountType,
      sellingFee: value.sellingFee,
      minFee: value.minFee,
      flatFee: value.flatFee,
      isDefault: checked,
      categories: value.categories,
      option1Value: value.option1Value,
      option2Value: value.option2Value,
      option3Value: value.option3Value,
      category: value.category,
      isPaid: value.isPaid,
      tierName: value.tierName,
      isLocationTier: value.isLocationTier,
      location: value.location,
    };
    onUpdate(dispatch, updateItem, updateItem.id);
  };

  const columns = [
    {
      title: "Consigner Type",
      dataIndex: "accountType",
      sorter: (a: any, b: any) => a.accountType.localeCompare(b.accountType),
      editable: false,
      required: true,
    },
    {
      title: "Category",
      dataIndex: "category",
      editable: true,
      required: false,
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: "Option 1",
      dataIndex: "option1Value",
      editable: true,
      required: false,
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: "Option 2",
      dataIndex: "option2Value",
      editable: true,
      required: false,
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: "Option 3",
      dataIndex: "option3Value",
      editable: true,
      required: false,
      render: (text: string) => <span>{text}</span>,
    },

    {
      title: "Default (only 1)",
      dataIndex: "isDefault",
      editable: true,
      required: true,
      width: "15%",
      render: (e: boolean | undefined, record: Item) => (
        <Switch
          onChange={(checked) => handleSwitchChange(checked, record)}
          defaultChecked={record.isDefault}
        />
      ),
    },
    {
      title: "Tier",
      dataIndex: "tierName",
      // width: "10%",
      editable: true,
      required: true,
      render: (tierName: any) => (
        <>{tierName ? <div>{`${tierName}`}</div> : ""}</>
      ),
    },
    {
      title: "Fee %",
      dataIndex: "sellingFee",
      width: "10%",
      editable: true,
      required: true,
      render: (sellingFee: any) => (
        <>{sellingFee ? <div>{`${sellingFee}%`}</div> : ""}</>
      ),
    },
    {
      title: `Min Fee ${getSymbolFromCurrency(store.currency)}`,
      dataIndex: "minFee",
      width: "10%",
      editable: true,
      required: true,
      render: (minFee: any) => (
        <>
          {minFee ? (
            <div>{`${getSymbolFromCurrency(store.currency)}${minFee}`}</div>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      title: `Flat Fee ${getSymbolFromCurrency(store.currency)}`,
      dataIndex: "flatFee",
      width: "10%",
      editable: true,
      required: true,
      render: (flatFee: any) => (
        <>
          {flatFee ? (
            <div>{`${getSymbolFromCurrency(store.currency)}${flatFee}`}</div>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "operation",
      width: "10%",
      render: (_: any, record: Item) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{ marginRight: 8 }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
          >
            Edit
          </Typography.Link>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: col.dataIndex,
        // === "sellingFee" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <div>
      <Button type="primary" style={{ marginBottom: 16 }} onClick={showModal}>
        Add consigner type
      </Button>
      <Modal
        title="Add consigner type"
        visible={isModalVisible}
        onOk={form2.submit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={form2.submit}
          >
            Submit
          </Button>,
        ]}
      >
        <Form form={form2} onFinish={handleAdd} layout="vertical">
          <Form.Item name="isPaid" label="Paid" valuePropName="checked">
            <Switch />
          </Form.Item>
          <Form.Item
            name="isLocationTier"
            label="Location Tier"
            valuePropName="checked"
          >
            <Switch
              onChange={() => setIsLocationTierEnabled(!isLocationTierEnabled)}
            />
          </Form.Item>
          <Form.Item name="location" label="Location">
            <Select
              placeholder="Select location"
              showSearch
              allowClear
              data-testid="consigners"
              disabled={!isLocationTierEnabled}
            >
              {inventoryFilterOptions.locations?.map((loc) => (
                <Select.Option key={loc.id} value={loc.value}>
                  {loc.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="tierName"
            rules={[{ required: true, message: "Tier Name required" }]}
            label="Tier Name"
          >
            <Input placeholder={"Tier Name"} />
          </Form.Item>
          <Form.Item
            name="accountType"
            rules={[{ required: true, message: "Consigner type required" }]}
            label="Type Name"
          >
            <Input placeholder={"Consigner Type Name"} />
          </Form.Item>
          <Form.Item name="category" label="Category">
            <Input placeholder={"Category"} />
          </Form.Item>
          <Form.Item label="Option 1" name="option1Value">
            <Input placeholder={"Option 1 Value"} />
          </Form.Item>
          <Form.Item label="Option 2" name="option2Value">
            <Input placeholder={"Option 2 Value"} />
          </Form.Item>
          <Form.Item label="Option 3" name="option3Value">
            <Input placeholder={"Option 3 Value"} />
          </Form.Item>
          <Form.Item
            name="sellingFee"
            rules={[{ required: true, message: "Fee required" }]}
            label="Fee %"
          >
            <Input suffix="%" placeholder={"Fee, ex 5%"} />
          </Form.Item>
          <Form.Item
            name="minFee"
            rules={[{ required: true, message: "Min fee required" }]}
            label="Min Fee"
          >
            <Input
              prefix={`${getSymbolFromCurrency(store.currency)}`}
              placeholder={`Min Fee, ex ${getSymbolFromCurrency(
                store.currency
              )}50`}
            />
          </Form.Item>
          <Form.Item
            name="flatFee"
            // rules={[{ required: true, message: "Flat fee required" }]}
            label="Flat Fee"
          >
            <Input
              prefix={`${getSymbolFromCurrency(store.currency)}`}
              placeholder={`Flat Fee, ex ${getSymbolFromCurrency(
                store.currency
              )}1`}
            />
          </Form.Item>
        </Form>
      </Modal>

      <Form form={form} component={false}>
        <Table
          // rowSelection={{
          //   type: "radio",
          //   ...rowSelection,
          // }}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={data}
          columns={mergedColumns}
          loading={loading}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel,
          }}
        />
      </Form>
    </div>
  );
};

export default ConsignerTypeTable;
