import React, { FC } from "react";
import { ReactComponent as Logo } from "../../assets/images/svg/next-arrow.svg";
import {
  StyledModalTitle,
  CircleSpan,
  ModalMainTitle,
  ModalSideTtle,
  ModalButtonDiv,
  FooterButtonFirst,
  FooterButtonSecond,
  StyledModal,
  FooterSettingsButtonFirst,
} from "../../styles/PopUpModals";

interface ReusableModalProps {
  isOpen: boolean;
  onClose: () => void;
  onPrimaryAction: () => void;
  onSecondaryAction?: () => void;
  title: string;
  step: string;
  mainTitle: string;
  sideTitle?: string;
  primaryButtonText: string;
  secondaryButtonText?: string;
  children?: React.ReactNode;
}

const ReusableModal: FC<ReusableModalProps> = ({
  isOpen,
  onClose,
  onPrimaryAction,
  onSecondaryAction,
  title,
  step,
  mainTitle,
  sideTitle,
  primaryButtonText,
  secondaryButtonText,
  children,
}) => {
  const isGoToSettings = primaryButtonText === "GO TO SETTING";

  return (
    <StyledModal
      title={
        <StyledModalTitle>
          <span>{title}</span> <CircleSpan>{step}</CircleSpan>
        </StyledModalTitle>
      }
      visible={isOpen}
      footer={null}
      closable={true}
      onCancel={onClose}
      maskClosable={true}
      width={754}
    >
      <ModalMainTitle>{mainTitle}</ModalMainTitle>
      <ModalSideTtle>{sideTitle}</ModalSideTtle>
      {children}
      <ModalButtonDiv centerContent={isGoToSettings}>
        {isGoToSettings ? (
          <FooterSettingsButtonFirst onClick={onPrimaryAction}>
            {primaryButtonText}
          </FooterSettingsButtonFirst>
        ) : (
          <FooterButtonFirst onClick={onPrimaryAction}>
            {primaryButtonText}
          </FooterButtonFirst>
        )}
        {secondaryButtonText && (
          <FooterButtonSecond onClick={onSecondaryAction}>
            {secondaryButtonText}{" "}
            <Logo style={{ width: "20px", height: "20px" }} />
          </FooterButtonSecond>
        )}
      </ModalButtonDiv>
    </StyledModal>
  );
};

export { ReusableModal };
