export const convertValue = (value: number, unit: any, currency) => {
  let formattedValue = "";

  if (value == null) {
    return ""; // Or any default value you want to return for null values
  }
  if (unit) {
    formattedValue = value.toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
  } else {
    formattedValue = value.toLocaleString("en-US", {
      style: "currency",
      currency: currency ?? "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
  }
  return formattedValue;
};
